@import '../../../../vars';

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &-header {
      font-weight: bold;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &__forget {
    text-align: left;
    margin: 20px 0;
    text-decoration: underline;

    > a {
      color: $info-grey;
      font-size: 12px;
    }
  }

  .other-auth-option {
    display: flex;
    justify-content: center;
    padding-top: 24px;

    > span:first-child {
      font-size: 16px;
      padding-right: 12px;
    }

    a {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__errors {
    text-align: left;
    color: $danger-color;
  }

  &__footer {
    padding-top: 40px;
  }

  .captcha {
    margin-left: 20px;
  }
}
