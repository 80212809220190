:root {
  --border-color: #ccd0e2;
  --primary-color: #2979ff;
  --secondary-color: #f8f8f8;
  --light-primary-color: #eef4ff;
  --disabled-color: #caf1fb;
  --body-color: #636b79;
  --danger-color: #e84060;
  --line-color: #e5e5eb;
  --black: #000;
  --white: #fff;
  --dark: #001529;

  --dark-grey-color: #566382;
  --light-grey-color: #b5b5c0;
  --lightest-grey-color: #f9f9f9;
  --info-grey: #636b79;

  --search-text-grey: #959595;
  --search-icon-grey: #d7d7d7;

  --banner-empty-background: #ededed;

  --text-primary: #000000;
  --text-secondary: #7d7d7d;

  --badge-default: #b3b9c4;
  --badge-success: #00c24e;
  --badge-danger: #e84060;
  --badge-warning: #f2994a;
  --badge-info: #2979ff;

  --sale-color: #E85E58;
  --product-color: #222222;

  --input-border-focus: #2979ff;

  --footer-height: 70px;

  --skeleton-color: #EFF0F2;

  --border-color-mall-theme: #EFEFEF;

  --input-placeholder: #AAAAAA;

  --skeleton-linear: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(255, 255, 255, 0) 100%);
}

@import './vars';

html {
  scroll-behavior: smooth;
}

h2 {
  font-weight: 400;
}

body {
  overflow-y: scroll;
  font-family: 'Inter', sans-serif;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

// Globals classnames
.text-center {
  text-align: center;
}

.full-page-pane {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 10;
  background-color: $white;
}

.form-layout {
  max-width: 340px;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
  }
}

.cards-row {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  & + .cards-row {
    margin-top: 80px;
  }
}

.m-bottom-0 {
  margin-bottom: 0;
}

// Ant design overrides
.ant-input,
.ant-select-selection {
  border-bottom: 1px solid $border-color;
  border-top-width: 0;
  font-size: 16px;
}

.ant-input-affix-wrapper,
.ant-input-prefix-wrapper,
.ant-select.ant-select-single .ant-select-selector,
.ant-select.ant-select-multiple .ant-select-selector {
  border-bottom-width: 1px;
}

.ant-select-selector {
  border-bottom-color: $border-color !important;
}
// Fix displaying text cursor in multiple select input
.ant-select-selection-search-input {
  padding: 0;
}

.ant-checkbox-inner {
  border-width: 1px;
}

.ant-form-explain {
  margin-top: 2px;
}

h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2,
h4.ant-typography,
.ant-typography h4,
h3.ant-typography,
.ant-typography h3 {
  font-weight: 400;
}

h3.ant-typography,
.ant-typography h3 {
  font-size: 22px;
}

.ant-menu-inline .ant-menu-item::after {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: bold;
}

.ant-card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  .ant-card-head-title {
    font-size: 22px;
    padding: 40px 0 10px;
  }

  .ant-card-body {
    padding: 0 24px 24px;
  }
}

.ant-btn {
  font-weight: bold;
}

.ant-btn-sm {
  height: 36px;
  padding: 0 32px;
  font-size: 14px;
  border-radius: 6px;
}

.confirmation-modal {
  .ant-btn {
    width: 120px;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $dark;
}

div.ant-typography {
  white-space: pre-wrap;
}

.ant-dropdown-trigger.anticon-ellipsis {
  font-size: 18px;
}

// Tabs style override
.ant-tabs {
  overflow: visible;
}

.ant-tabs-top-bar {
  border-bottom: 1px solid $line-color;

  .ant-tabs-tab-active {
    font-weight: bold;
    color: $black;
  }

  .ant-tabs-tab {
    font-size: 16px;

    &:hover {
      color: $black;
    }
  }

  .ant-tabs-ink-bar {
    background-color: $black;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item-label > label {
  color: $body-color;
  height: 36px;
}

.ant-form-item {
  align-items: flex-start !important;

  .ant-form-item-label {
    height: 41px;
    align-items: center;
  }
}

.ant-form-item-control {
  width: 100%;
}

.ant-modal-content {
  .ant-modal-title {
    font-size: 22px;
    color: $black;
  }
  .ant-modal-close {
    padding: 20px;
  }

  .ant-modal-close-x {
    display: none;
  }

  border-radius: 10px;
  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 40px 40px 0 40px;
  }

  .ant-modal-body {
    padding: 30px 40px 30px;
  }

  .ant-modal-footer {
    display: flex;
    padding: 0 40px 40px 40px;

    .ant-btn {
      font-weight: 600;
      padding: 10px 35px;
      font-size: 16px;
      line-height: 100%;

      &:first-child {
        border: 1px solid $primary-color;
        color: $primary-color;
      }

      &-primary {
        margin: 0 20px 0 0;
      }
    }

    div {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.form-spaces {
  :global .ant-form-item {
    justify-content: space-between;
    align-items: center;

    .ant-form-item-label {
      display: flex;
      align-items: flex-start;
    }
    .ant-form-item-control {
      flex: 0 0 400px;
      flex-grow: unset;
    }
  }
}

//Popup
.ant-popover-inner {
  width: 180px;
  height: 90px;
  overflow: hidden;
}

//Layout
.ant-layout-sider {
  background-color: $white;
}

//Avatar
.ant-avatar-string {
  position: static;
}

.ant-avatar-lg {
  line-height: normal;
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    font-weight: normal;
    background: unset;
  }
}

//Uploader
.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

//Drawer
.ant-drawer-header {
  border-bottom: 1px solid $line-color;
  padding: 32px;
}

.ant-drawer-title {
  font-size: 22px;
  font-weight: bold;
}

.ant-tooltip-arrow:before {
  background-color: $white;
}

.ant-tooltip-inner {
  padding: 14px;
  border-radius: 2px;
  background-color: $white;
  color: $body-color;
}

.ant-no-border-input {
  border-bottom: none;
}

th {
  text-align: left;
}

.btn-link {
  padding: 0;
  height: min-content;
}

.btn-outline {
  color: $primary-color;
  border: 1px solid $primary-color;
}

.copy-icon {
  fill: $primary-color;
  cursor: pointer;
}

//Select and option
.ant-select-selection-placeholder {
  opacity: 0.5;
}

//Ant profile menu dropdown
.ant-dropdown-placement-topRight {
  left: 64px !important;
}

//Dashboard
.dashboard__bottom {
  padding: 16px;
}
