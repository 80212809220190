@import '../../../vars';

.notification-item {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 30px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  display: table;
  width: fit-content;
  margin: 10px 0;

  font-size: 16px;
  z-index: 100;

  &__content {
    display: flex;
    align-items: center;

    padding: 0 20px;
    min-height: 51px;
  }

  &__icon {
    margin-right: 8px;

    display: flex;
    align-items: center;
  }

  @media (max-width: 567px) {
    font-size: 14px;

    &__content {
      padding: 0 15px;
      min-height: 35px;
    }
  }
}
