@import '../../../../vars';

.verification-code-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  height: 100%;

  &__errors {
    span {
      color: $danger-color;
    }
  }

  &__content {
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__footer {
    padding-top: 40px;

    div:first-child {
      max-width: 340px;
      margin: 0 auto;
    }

    .other-auth-option {
      padding-top: 24px;
      font-size: 16px;

      > span:first-child {
        padding-right: 12px;
      }

      > span:last-child {
        font-weight: 600;
        color: $primary-color;
      }
    }
  }

  .captcha {
    margin-left: 20px;
  }
}
