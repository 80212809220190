@import '../../../../vars';

.dashboard {
  min-height: 100vh;

  &__aside {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    left: 0;

    .ant-layout-sider-children {
      background-color: $lightest-grey-color;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .ant-menu {
      margin-top: 30px;
    }
  }

  &__logo {
    position: relative;
    width: 100%;

    padding: 16px;
  }

  &__menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottom {
    padding: 36px;
  }

  &__main {
    margin-left: 64px;
    position: relative;
    padding-bottom: $footer-height;
  }

  &__content {
    padding: 76px 64px 0;
  }

  @media (max-width: 567px) {
    &__content {
      margin: 0;
    }

    .ant-layout-sider-children {
      display: none;
    }

    &__main {
      margin-left: 0;
    }
  }
}
