// Variables
$border-color: #ccd0e2;
$primary-color: #2979ff;
$light-primary-color: #98e5f8;
$disabled-color: #caf1fb;
$body-color: #636b79;
$danger-color: #e84060;
$line-color: #e5e5eb;
$black: #000;
$white: #fff;
$dark: #001529;

$dark-grey-color: #566382;
$light-grey-color: #b5b5c0;
$lightest-grey-color: #f9f9f9;
$info-grey: #636b79;

$badge-default: #b3b9c4;
$badge-success: #00c24e;
$badge-danger: #e84060;
$badge-warning: #f2994a;
$badge-info: #2979ff;

$input-border-focus: #2979ff;

$footer-height: 70px;
